<div
  class="background-image w-full flex flex-column overflow-y-hidden justify-content-around"
>
  <app-label-description-custom
    *ngIf="productionSiteId"
    [icon]="'pi pi-pencil'"
    [title]="
      'features.production-site-page.upsert-production-site.edit' | translate
    "
    [subtitle]="'(' + name + ')'"
  ></app-label-description-custom>
  <app-label-description-custom
    *ngIf="!productionSiteId"
    [icon]="'pi pi-tag'"
    [title]="
      'features.production-site-page.upsert-production-site.new-production-site'
        | translate
    "
  ></app-label-description-custom>
  <form
    [formGroup]="productionSiteForm"
    class="flex flex-column w-full align-items-start height-upsert p-5 flex-grow"
    (ngSubmit)="onUpsertProductionSite()"
  >
    <span class="font-bold py-2">{{
        'features.production-site-page.upsert-production-site.title' | translate
      }}</span>
    <div class="flex w-full flex-column h-full md:flex-row">
      <div class="w-full md:w-6 p-fluid h-full pb-8 md:pb-0 md:py-3 md:pr-3">
        <div class="flex flex-row align-items-center mb-1">
          <label>
            {{
              'features.production-site-page.upsert-production-site.place-on-map'
                | translate
            }}<i
            class="ml-5 pi pi-info-circle text-sm"
            [pTooltip]="
                'features.production-site-page.upsert-production-site.title-hover'
                  | translate
              "
            tooltipPosition="right"
          ></i
          ></label>
        </div>
        <app-map
          *ngIf="productionSiteId"
          [latitude]="latitude"
          [longitude]="longitude"
          (mapClick)="onMapClick($event)"
        ></app-map>
        <app-map
          *ngIf="!productionSiteId"
          (mapClick)="onMapClick($event)"
        ></app-map>
      </div>
      <div class="w-full md:w-6 p-fluid pt-8 h-full md:pt-6 md:pb-2 md:pl-3">
        <div class="flex flex-column h-full justify-content-between w-full">
          <div
            class="flex flex-column md:gap-6 h-full justify-content-center md:flex-row w-full"
          >
            <div
              class="flex flex-column md:gap-2 w-full justify-content-start md:w-6"
            >
              <div class="w-full p-fluid py-4 md:py-2 flex-grow-1">
                <p-floatLabel class="w-full">
                  <input pInputText type="text" formControlName="place" />
                  <label>
                    <!--        for="float-label">-->
                    {{
                      'features.production-site-page.upsert-production-site.place-label'
                        | translate
                    }}</label
                  ></p-floatLabel
                >
              </div>
              <div class="w-full p-fluid py-4 md:py-2 flex-grow-1">
                <p-floatLabel class="w-full">
                  <input pInputText type="text" formControlName="name" />
                  <label>
                    <!--        for="float-label">-->
                    {{
                      'features.production-site-page.upsert-production-site.production-site-name-label'
                        | translate
                    }}</label
                  ></p-floatLabel
                >
              </div>

              <div class="w-full p-fluid py-4 md:py-2 flex-grow-1">
                <p-floatLabel class="w-full">
                  <input pInputText type="number" formControlName="capacity" />
                  <label>
                    <!--        for="float-label">-->
                    {{
                      'features.production-site-page.upsert-production-site.capacity-label'
                        | translate
                    }}</label
                  ></p-floatLabel
                >
              </div>

              <div class="md:flex flex-column hidden w-full p-fluid py-3">
                <app-upload-button-custom
                  [hasFiles]="hasAttachments"
                  [selectedFilesAttachment]="selectedFilesAttachmentFromGet"
                  (filesSelected)="onFilesSelected($event)"
                  [acceptedFileTypes]="ExtensionsEnum.PDF"
                  [maxFileSize]="environment.maxSizeFiles"
                  [numberFileLimit]="numberFileLimit"
                  [disabled]="false"
                  [visible]="visibleUploadDialog"
                  (fileRemoved)="onFileRemoved($event)"
                ></app-upload-button-custom>
              </div>
              <div class="md:flex flex-column hidden w-full p-fluid py-3">
                <app-upload-button-custom
                  [hasFiles]="hasImages"
                  (filesSelected)="onImageFileSelected($event)"
                  [acceptedFileTypes]="ExtensionsEnum.IMAGE"
                  [maxFileSize]="environment.maxSizeFiles"
                  [numberFileLimit]="numberImageLimit"
                  [selectedFilesAttachment]="selectedImagesAttachmentFromGet"
                  [visible]="visibleUploadDialog"
                  (fileRemoved)="onImageRemoved($event)"
                ></app-upload-button-custom>
              </div>
            </div>
            <div
              class="flex flex-column md:gap-2 w-full justify-content-start md:w-6"
            >
              <div class="w-full p-fluid py-4 md:py-2 flex-grow-1">
                <p-floatLabel class="w-full">
                  <p-dropdown
                    [options]="types"
                    formControlName="selectedType"
                    optionLabel="label"
                    optionValue="value"
                    [placeholder]="'shared.search' | translate"
                  >
                  </p-dropdown>
                  <label>
                    {{
                      'features.production-site-page.upsert-production-site.type-label'
                        | translate
                    }}</label
                  ></p-floatLabel
                >
              </div>
              <div class="w-full p-fluid py-4 md:py-2">
                <p-floatLabel class="w-full">
                  <textarea
                    rows="{{ rows }}"
                    cols="30"
                    pInputTextarea
                    formControlName="description"
                  ></textarea>
                  <label>
                    <!--        for="float-label">-->
                    {{
                      'features.production-site-page.upsert-production-site.description-label'
                        | translate
                    }}</label
                  ></p-floatLabel
                >
                <div class="md:hidden flex flex-column w-full p-fluid py-5">
                  <app-upload-button-custom
                    [hasFiles]="hasAttachments"
                    [selectedFilesAttachment]="selectedFilesAttachmentFromGet"
                    (filesSelected)="onFilesSelected($event)"
                    [acceptedFileTypes]="ExtensionsEnum.PDF"
                    [maxFileSize]="environment.maxSizeFiles"
                    [numberFileLimit]="numberFileLimit"
                    [visible]="visibleUploadDialog"
                    (fileRemoved)="onFileRemoved($event)"
                  ></app-upload-button-custom>
                </div>
                <div
                  class="md:hidden flex flex-row w-full justify-content-between py-5"
                >
                  <p-button
                    [icon]="'pi pi-chevron-left'"
                    [label]="'shared.back' | translate"
                    [outlined]="true"
                    severity="secondary"
                    [styleClass]="'secondary-custom'"
                    (onClick)="openDialogBack()"
                  />
                  <p-button
                    [label]="
                      (productionSiteId || productionSiteId
                        ? 'shared.update'
                        : 'shared.create'
                      ) | translate
                    "
                    [raised]="true"
                    [styleClass]="'primary-custom'"
                    [disabled]="isButtonDisabled()"
                    (onClick)="openDialogConfirm()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden md:flex flex-row w-full justify-content-between py-5">
      <p-button
        [icon]="'pi pi-chevron-left'"
        [label]="'shared.back' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="openDialogBack()"
      />
      <p-button
        [label]="
          (productionSiteId || productionSiteId
            ? 'shared.update'
            : 'shared.create'
          ) | translate
        "
        [raised]="true"
        [styleClass]="'primary-custom'"
        [disabled]="isButtonDisabled()"
        (onClick)="openDialogConfirm()"
      />
    </div>

    <p-dialog
      [modal]="true"
      [(visible)]="dialogUpsertVisible"
      [style]="{ width: '50rem' }"
      [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
    >
      <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-start gap-2">
          <i class="pi pi-exclamation-circle text-white"></i>
          <span class="text-white text-2xl white-space-nowrap">
            {{ 'shared.attention' | translate }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="flex flex-column gap-3" *ngIf="!productionSiteId">
          <span
            class="font-bold text-lg text-black-alpha-90 white-space-nowrap"
          >
            {{ 'shared.message-confirm-production-site-1' | translate }}
          </span>
          <span class="text-lg text-black-alpha-90 white-space-nowrap">
            {{ 'shared.message-go-back-2' | translate }}
          </span>
        </div>
        <div class="flex flex-column gap-3" *ngIf="productionSiteId">
          <span
            class="font-bold text-lg text-black-alpha-90 white-space-nowrap"
          >
            {{ 'shared.message-update-production-site-1' | translate }}
          </span>
          <span class="text-lg text-black-alpha-90 white-space-nowrap">
            {{ 'shared.message-go-back-2' | translate }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="flex flex-row w-full justify-content-end gap-2 p-2">
          <p-button
            [label]="'shared.cancel' | translate"
            [outlined]="true"
            severity="secondary"
            [styleClass]="'secondary-custom'"
            (onClick)="closeDialogConfirm()"
          />
          <p-button
            type="submit"
            [label]="'shared.confirm' | translate"
            [raised]="true"
            [styleClass]="'primary-custom'"
          />
        </div>
      </ng-template>
    </p-dialog>
  </form>
</div>
<p-dialog
  [modal]="true"
  [(visible)]="dialogGoBackVisible"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-start gap-2">
      <i class="pi pi-exclamation-circle text-white"></i>
      <span class="text-white text-2xl white-space-nowrap">
        {{ 'shared.attention' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-column gap-3">
      <span
        class="font-bold text text-black-alpha-90 text-lg white-space-nowrap"
      >
        {{ 'shared.message-go-back-1' | translate }}
      </span>
      <span class="text-lg text text-black-alpha-90 white-space-nowrap">
        {{ 'shared.message-go-back-2' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row w-full justify-content-end gap-2 p-2">
      <p-button
        [label]="'shared.cancel' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="closeDialogBack()"
      />
      <p-button
        type="submit"
        [label]="'shared.confirm' | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        (onClick)="goBack()"
      />
    </div>
  </ng-template>
</p-dialog>
