import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RoutingResourceEnum } from '../../../enums/routing-resource-enum';
import { StorageService } from '../../../services/storage.service';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { Button } from 'primeng/button';
import { Router } from '@angular/router';
import { NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { RoleEnum } from '../../../enums/role-enum';
import { slugify } from '../../../utils/string-utils';
import { AssetsService } from '../../../services/assets.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MenuModule,
    Button,
    NgIf,
    OverlayPanelModule,
    NgForOf,
    AvatarModule,
    TitleCasePipe,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  constructor(
    protected storage: StorageService,
    protected assetsService: AssetsService,
    private router: Router,
  ) {}

  @ViewChild('op') op!: OverlayPanel;

  @Input() selectedLanguage!: string;

  @Input() languages!: { label: string; value: string }[];
  @Input() changeLanguage!: (lang: string) => void;
  name!: string;
  role!: string;
  items: MenuItem[] | undefined;
  imageUrl: string | undefined;
  isOverlayPanelVisible: boolean = false;

  ngOnInit(): void {
    this.items = [
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => this.logout(),
      },
    ];
    // FIXME: cambio permanente nel sito una volta scelta la lingua (?)
    this.getUserData();
    if (this.storage.getProducer()?.name) {
      if (this.storage.getProducer()?.image) {
        this.imageUrl = this.storage.getProducer()?.image;
      } else {
        const producerName = this.storage.getProducer()?.name || '';
        const fileName = this.getLogoFileName(producerName);
        this.assetsService.getFile(fileName).subscribe({
          next: (response) => {
            this.imageUrl = response;
          },
          error: (err) => {
            console.warn(
              `Logo not found for producer ${producerName} (filename):`,
              fileName,
            );
          },
        });
      }
    }
  }

  getLogoFileName(producerName: string): string {
    const slugifiedProducerName = slugify(producerName);
    return `logo_producer_${slugifiedProducerName}.png`;
  }

  getUserData(): void {
    const userData = this.storage.getProfile();
    if (userData) {
      this.name = userData.name;
      this.role = userData.role;
    }
  }

  logout(): void {
    this.storage.removeAll();
    void this.router.navigate([`${RoutingResourceEnum.LOGIN}`]);
  }

  onButtonClick(event: any): void {
    this.op.toggle(event);
    this.isOverlayPanelVisible = !this.isOverlayPanelVisible;
  }

  changeLanguageHandler(lang: string, event: any): void {
    this.changeLanguage(lang);
    this.op.toggle(event);
    this.isOverlayPanelVisible = !this.isOverlayPanelVisible;
  }

  goHome() {
    void this.router.navigate([RoutingResourceEnum.HOME]);
  }

  protected readonly RoleEnum = RoleEnum;
}
