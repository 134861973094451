<p-toast position="bottom-right"></p-toast>
<div class="background-image w-full flex flex-column justify-content-around">
  <app-label-description-custom
    *ngIf="productId"
    [icon]="'pi pi-pencil'"
    [title]="'features.product-page.upsert-product.edit' | translate"
    [subtitle]="
      '(' + (selectedSpecies ? selectedSpecies.scientificName : '') + ')'
    "
  ></app-label-description-custom>
  <app-label-description-custom
    *ngIf="!productId"
    [icon]="'pi pi-tag'"
    [title]="'features.product-page.upsert-product.new-product' | translate"
  ></app-label-description-custom>
  <form
    [formGroup]="productForm"
    class="flex flex-column w-full align-items-start justify-content-between height-upsert p-5 flex-grow"
    (ngSubmit)="onUpsertProduct()"
  >
    <div class="h-full w-full">
      <span class="font-bold py-2">{{
        'features.product-page.upsert-product.fao-code-1' | translate
      }}</span>
      <div class="w-full p-fluid md:w-3 py-5 md:pr-3">
        <label>
          {{
            'features.product-page.upsert-product.fao-code-label' | translate
          }}</label
        >
        <p-dropdown
          optionLabel="alpha3Code"
          optionValue="_id"
          [style]="{ height: '3rem' }"
          [options]="species"
          formControlName="speciesId"
          [placeholder]="'shared.search' | translate"
        />
      </div>
      <div
        class="flex flex-column w-full"
        [ngClass]="{
          'disabled-section': !productForm.value['speciesId'],
        }"
      >
        <span class="font-bold py-2">{{
          'features.product-page.upsert-product.fao-code-2' | translate
        }}</span>

        <div class="flex flex-column w-full">
          <div class="flex flex-column md:flex-row md:gap-4">
            <div class="w-full p-fluid md:w-3 py-5">
              <p-floatLabel class="w-full">
                <input
                  pInputText
                  [disabled]="true"
                  [value]="
                    selectedSpecies
                      ? ('features.species.' + selectedSpecies.alpha3Code
                        | translate)
                      : ''
                  "
                  type="text"
                />
                <label>
                  {{
                    'features.product-page.upsert-product.fao-code-label1'
                      | translate
                  }}</label
                ></p-floatLabel
              >
            </div>
            <div class="w-full p-fluid md:w-3 py-5">
              <p-floatLabel class="w-full">
                <input
                  pInputText
                  [disabled]="true"
                  type="text"
                  [value]="
                    selectedSpecies ? selectedSpecies.scientificName : ''
                  "
                />
                <label>
                  {{
                    'features.product-page.upsert-product.fao-code-label2'
                      | translate
                  }}</label
                ></p-floatLabel
              >
            </div>
            <div class="w-full p-fluid md:w-3 py-5">
              <p-floatLabel class="w-full">
                <input formControlName="name" pInputText type="text" />
                <label>
                  <!--        for="float-label">-->
                  {{
                    'features.product-page.upsert-product.fao-code-label3'
                      | translate
                  }}</label
                ></p-floatLabel
              >
            </div>
            <div class="w-full p-fluid md:w-3 py-5">
              <app-upload-button-custom
                [hasFiles]="hasAttachments"
                *ngIf="productForm"
                [disabled]="!productForm.value['speciesId']"
                (filesSelected)="onFilesSelected($event)"
                [acceptedFileTypes]="ExtensionsEnum.IMAGE"
                [maxFileSize]="environment.maxSizeFiles"
                [numberFileLimit]="numberFileLimit"
                [selectedFilesAttachment]="selectedImagesAttachmentFromGet"
                [visible]="visibleUploadDialog"
                (fileRemoved)="onFileRemoved($event)"
              ></app-upload-button-custom>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full justify-content-between py-4">
      <p-button
        [icon]="'pi pi-chevron-left'"
        [label]="'shared.back' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="openDialogBack()"
      />
      <p-button
        [label]="(productId ? 'shared.update' : 'shared.create') | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        [disabled]="isButtonDisabled()"
        (onClick)="openDialogConfirm()"
      />
    </div>
    <p-dialog
      [modal]="true"
      [(visible)]="dialogUpsertVisible"
      [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
    >
      <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-start gap-2">
          <i class="pi pi-exclamation-circle text-white"></i>
          <span class="text-white text-2xl white-space-nowrap">
            {{ 'shared.attention' | translate }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="flex flex-column gap-3" *ngIf="!productId">
          <span
            class="font-bold text-lg text-black-alpha-90 white-space-nowrap"
          >
            {{ 'shared.message-confirm-product-1' | translate }}
          </span>
          <span class="text-lg text-black-alpha-90 white-space-nowrap">
            {{ 'shared.message-go-back-2' | translate }}
          </span>
        </div>
        <div class="flex flex-column gap-3" *ngIf="productId">
          <span
            class="font-bold text-lg text-black-alpha-90 white-space-nowrap"
          >
            {{ 'shared.message-update-product-1' | translate }}
          </span>
          <span class="text-lg text-black-alpha-90 white-space-nowrap">
            {{ 'shared.message-go-back-2' | translate }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="flex flex-row w-full justify-content-end gap-2 p-2">
          <p-button
            [label]="'shared.cancel' | translate"
            [outlined]="true"
            severity="secondary"
            [styleClass]="'secondary-custom'"
            (onClick)="closeDialogConfirm()"
          />

          <p-button
            type="submit"
            [label]="'shared.confirm' | translate"
            [raised]="true"
            [styleClass]="'primary-custom'"
          />
        </div>
      </ng-template>
    </p-dialog>
  </form>
</div>
<p-dialog
  [modal]="true"
  [(visible)]="dialogGoBackVisible"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-start gap-2">
      <i class="pi pi-exclamation-circle text-white"></i>
      <span class="text-white text-2xl white-space-nowrap">
        {{ 'shared.attention' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-column gap-3">
      <span
        class="font-bold text text-black-alpha-90 text-lg white-space-nowrap"
      >
        {{ 'shared.message-go-back-1' | translate }}
      </span>
      <span class="text-lg text text-black-alpha-90 white-space-nowrap">
        {{ 'shared.message-go-back-2' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row w-full justify-content-end gap-2 p-2">
      <p-button
        [label]="'shared.cancel' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="closeDialogBack()"
      />
      <p-button
        type="submit"
        [label]="'shared.confirm' | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        (onClick)="goBack()"
      />
    </div>
  </ng-template>
</p-dialog>
