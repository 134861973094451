import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResourceEnum } from '../../../core/enums/api-resource-enum';
import { ApiActionEnum } from '../../../core/enums/api-action-enum';
import { HttpClient } from '@angular/common/http';
import { IndexResponseBatchDto } from '../dto/index-response-batch.dto';
import { ResponseBatchDto } from '../dto/response-batch.dto';
import { UpsertBatchDto } from '../dto/upsert-batch.dto';
import { BaseApiService } from '../../../core/services/base-api.service';
import { ResponseTraceBatchDto } from '../../trace-batch/dto/response-trace-batch.dto';
import { LocaleIdService } from '../../../core/services/locale-id.service';

@Injectable({
  providedIn: 'root',
})
export class BatchService extends BaseApiService {
  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) public locale: LocaleIdService,
  ) {
    super();
  }

  index(): Observable<IndexResponseBatchDto[]> {
    return this.http.get<IndexResponseBatchDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.BATCHES}/${ApiActionEnum.INDEX}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getAttachment(id: string, fileKey: any): Observable<string> {
    return this.http.get(
      `${this.baseUrl}/${ApiResourceEnum.BATCHES}/${id}/file-url/${fileKey}`,
      this.textOptions,
    );
  }

  deleteAttachment(id: string, fileKey: any): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}/${ApiResourceEnum.BATCHES}/${id}/file-url/${fileKey}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  exportPDF(id: string): Observable<Blob> {
    const locale = this.locale.valueOf();
    return this.http.get(
      `${this.baseUrl}/${ApiResourceEnum.BATCHES}/${ApiActionEnum.TRACE}/${id}/export/${locale}`,
      { ...this.textOptions, responseType: 'blob' },
    );
  }

  getTraceBatchById(id: string): Observable<ResponseTraceBatchDto> {
    return this.http.get<ResponseTraceBatchDto>(
      `${this.baseUrl}/${ApiResourceEnum.BATCHES}/${ApiActionEnum.TRACE}/${id}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getBatches(): Observable<ResponseBatchDto[]> {
    return this.http.get<ResponseBatchDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.BATCHES}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getBatchById(id: string): Observable<ResponseBatchDto> {
    return this.http.get<ResponseBatchDto>(
      `${this.baseUrl}/${ApiResourceEnum.BATCHES}/${id}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  createBatch(batch: UpsertBatchDto): Observable<ResponseBatchDto> {
    const formData = this.toFormData(batch);
    return this.http.post<ResponseBatchDto>(
      `${this.baseUrl}/${ApiResourceEnum.BATCHES}`,
      formData,
    );
  }

  updateBatch(batch: UpsertBatchDto, id: string): Observable<ResponseBatchDto> {
    const formData = this.toFormData(batch);
    return this.http.patch<ResponseBatchDto>(
      `${this.baseUrl}/${ApiResourceEnum.BATCHES}/${id}`,
      formData,
    );
  }

  private toFormData(batch: UpsertBatchDto): FormData {
    const formData = new FormData();
    formData.append('productionSiteId', batch.productionSiteId);
    formData.append('productId', batch.productId);
    formData.append('code', batch.code);
    formData.append('seedingDate', batch.seedingDate.toISOString());
    formData.append('seedingType', batch.seedingType);
    formData.append('growingProcessType', batch.growingProcessType);
    formData.append('quantity', batch.quantity.toString());
    for (const [index, i] of batch.nutrients.entries()) {
      formData.append(`nutrients[${index}][nutrient]`, i.nutrient);
      formData.append(`nutrients[${index}][value]`, i.value);
    }
    if (batch.OMGFreeCertFiles) {
      batch.OMGFreeCertFiles.forEach((file) => {
        formData.append('OMGFreeCertFiles', file, file.name);
      });
    }
    if (batch.antibioticFreeCertFiles) {
      batch.antibioticFreeCertFiles.forEach((file) => {
        formData.append('antibioticFreeCertFiles', file, file.name);
      });
    }
    if (batch.nutrientsFreeCertFiles) {
      batch.nutrientsFreeCertFiles.forEach((file) => {
        formData.append('nutrientsFreeCertFiles', file, file.name);
      });
    }

    return formData;
  }
}
