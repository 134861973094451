import { Component, HostListener, OnInit } from '@angular/core';
import { Button } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { LabelDescriptionCustomComponent } from '../../../../shared/components/label-description-custom/label-description-custom.component';
import { NgIf } from '@angular/common';
import { MessageService, PrimeTemplate } from 'primeng/api';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UploadButtonCustomComponent } from '../../../../shared/components/upload-button-custom/upload-button-custom.component';
import { ActivatedRoute, Router } from '@angular/router';

import { RoutingResourceEnum } from '../../../../core/enums/routing-resource-enum';
import { ProductionSiteService } from '../../services/production-site.service';
import { ResponseProductionSiteDto } from '../../dto/response-production-site.dto';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { UpsertProductionSiteDto } from '../../dto/upsert-production-site.dto';
import { ExtensionsEnum } from '../../../../core/enums/extensions-enum';
import { TooltipModule } from 'primeng/tooltip';
import { TypeProductionSiteEnum } from '../../../../core/enums/type-production-site-enum';
import { environment } from '../../../../../environments/environment';
import { PositionMapComponent } from '../../../../shared/components/position-map/position-map.component';
import { ResponseAttachmentDto } from '../../../../core/models/response-attachment.dto';

@Component({
  selector: 'app-upsert-production-site',
  standalone: true,
  imports: [
    Button,
    DialogModule,
    DropdownModule,
    FloatLabelModule,
    InputTextModule,
    LabelDescriptionCustomComponent,
    NgIf,
    PrimeTemplate,
    ReactiveFormsModule,
    TranslateModule,
    UploadButtonCustomComponent,
    InputTextareaModule,
    PositionMapComponent,
    TooltipModule,
  ],
  templateUrl: './upsert-production-site.component.html',
  styleUrl: './upsert-production-site.component.scss',
})
export class UpsertProductionSiteComponent implements OnInit {
  longitude!: number;
  latitude!: number;
  types!: { value: string; label: string }[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productionSiteService: ProductionSiteService,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {
    this.route.paramMap.subscribe((params) => {
      this.productionSiteId = params.get('id') ?? '';
    });
  }

  productionSiteForm!: FormGroup;
  productionSiteId!: string;
  name: string = '';
  productionSite!: ResponseProductionSiteDto;
  selectedFilesAttachment: File[] = [];
  selectedImagesAttachment: File[] = [];
  selectedFilesAttachmentFromGet: ResponseAttachmentDto[] = [];
  selectedImagesAttachmentFromGet: ResponseAttachmentDto[] = [];
  dialogGoBackVisible: boolean = false;
  dialogUpsertVisible: boolean = false;
  hasAttachments: boolean = false;
  hasImages: boolean = false;
  numberFileLimit: number = 3;
  numberImageLimit: number = 1;
  rows: number = 10;
  visibleUploadDialog!: boolean;
  attachmentDeleted: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.adjustTextareaRows();
  }

  adjustTextareaRows() {
    if (window.innerHeight > 700) {
      this.rows = 14;
    } else {
      this.rows = 8;
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.adjustTextareaRows();
    if (this.productionSiteId) {
      this.getProductionSite();
    }
    const typeValues = Object.values(TypeProductionSiteEnum);
    this.types = typeValues.map((i) => {
      return {
        value: i,
        // label: 'features.production-site-page.upsert-production-site.type.' + i,
        label: i, //FIXME
      };
    });
  }

  initForm(): void {
    this.productionSiteForm = new FormGroup({
      place: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      capacity: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        this.integerValidator,
      ]),
      selectedType: new FormControl(null, [Validators.required]),
      description: new FormControl(null, Validators.required),
    });
  }

  getProductionSite(): void {
    if (this.productionSiteId) {
      this.productionSiteService
        .getProductionSiteById(this.productionSiteId)
        .subscribe({
          next: (response: ResponseProductionSiteDto) => {
            this.productionSite = response;
            this.name = response.name;

            this.selectedFilesAttachmentFromGet = response.attachments;
            this.selectedImagesAttachmentFromGet = response.images;

            // if (response.attachments) {
            //   if (response.attachments?.length > 0) {
            //     this.hasAttachments = true;
            //   }
            // }

            this.hasAttachments = !!this.selectedFilesAttachmentFromGet.length;
            this.hasImages = !!this.selectedImagesAttachmentFromGet.length;

            this.productionSiteForm.patchValue({
              place: this.productionSite.place,
              name: this.productionSite.name,
              capacity: this.productionSite.capacity,
              selectedType: this.productionSite.type,
              description: this.productionSite.description,
            });
            this.latitude = response.position.lat;
            this.longitude = response.position.lng;
          },
          error: (err) => {},
        });
    }
  }

  onFilesSelected(files: File[]): void {
    this.selectedFilesAttachment = files;
  }

  onImageFileSelected(files: File[]): void {
    this.selectedImagesAttachment = files;
  }

  onUpsertProductionSite(): void {
    if (this.productionSiteForm.valid) {
      const payload = this.createPayload();
      if (this.productionSiteId) {
        this.updateProductionSite(payload);
      } else {
        this.createProductionSite(payload);
      }
    }
  }

  private createPayload(): UpsertProductionSiteDto {
    const payload: UpsertProductionSiteDto = {
      name: this.productionSiteForm.get('name')?.value,
      place: this.productionSiteForm.get('place')?.value,
      type: this.productionSiteForm.get('selectedType')?.value,
      capacity: this.productionSiteForm.get('capacity')?.value,
      description: this.productionSiteForm.get('description')?.value,
      position: {
        lat: this.latitude,
        lng: this.longitude,
      },
      files: this.selectedFilesAttachment,
      images: this.selectedImagesAttachment,
    };
    if (this.productionSiteId) {
      payload.id = this.productionSiteId;
    }
    return payload;
  }

  private updateProductionSite(payload: UpsertProductionSiteDto): void {
    this.productionSiteService.patchUpdateProductionSite(payload).subscribe({
      next: (response: ResponseProductionSiteDto) => this.onSuccess(),
      error: (err) => {
        this.closeDialogConfirm();
      },
    });
  }

  private createProductionSite(payload: UpsertProductionSiteDto): void {
    this.productionSiteService.postCreateProductionSite(payload).subscribe({
      next: (response: ResponseProductionSiteDto) => this.onSuccess(),
      error: (err) => {
        this.closeDialogConfirm();
      },
    });
  }

  private onSuccess(): void {
    let summary = this.translate.instant('shared.success');
    let detailCreate = this.translate.instant('shared.create-product-success');
    let detailUpdate = this.translate.instant('shared.update-product-success');
    if (this.productionSiteId) {
      this.messageService.add({
        severity: 'success',
        summary: summary,
        detail: detailUpdate,
        styleClass: 'custom-toast-success',
      });
    } else {
      this.messageService.add({
        severity: 'success',
        summary: summary,
        detail: detailCreate,
        styleClass: 'custom-toast-success',
      });
    }

    setTimeout(() => {
      this.goBack();
    }, 1000);
  }

  closeDialogConfirm(): void {
    this.dialogUpsertVisible = false;
  }

  closeDialogBack(): void {
    this.dialogGoBackVisible = false;
  }

  openDialogConfirm(): void {
    this.dialogUpsertVisible = true;
  }

  openDialogBack(): void {
    if (!this.productionSiteId) {
      if (!this.productionSiteForm.touched) {
        this.goBack();
      } else {
        this.dialogGoBackVisible = true;
      }
    } else {
      if (this.productionSiteForm.pristine) {
        this.goBack();
      } else {
        this.dialogGoBackVisible = true;
      }
    }
  }

  goBack(): void {
    void this.router.navigate([RoutingResourceEnum.PRODUCTION_SITES]);
  }

  onMapClick(event: { lng: number; lat: number }) {
    this.longitude = event.lng;
    this.latitude = event.lat;
  }

  integerValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!Number.isInteger(value)) {
      return { notInteger: true };
    }
    return null;
  }

  onFileRemoved(event: { fileKey: string }) {
    if (this.productionSiteId) {
      this.productionSiteService
        .deleteAttachment(this.productionSiteId, event.fileKey)
        .subscribe({
          next: () => {
            this.productionSite.attachments =
              this.productionSite.attachments.filter(
                (i) => i.fileKey !== event.fileKey,
              );
            this.attachmentDeleted = true;
          },
          error: (err) => {
            this.visibleUploadDialog = false;
          },
        });
    }
  }

  onImageRemoved(event: { fileKey: string }) {
    if (this.productionSiteId) {
      this.productionSiteService
        .deleteAttachment(this.productionSiteId, event.fileKey)
        .subscribe({
          next: () => {
            this.productionSite.images =
              this.productionSite.images.filter(
                (i) => i.fileKey !== event.fileKey,
              );
            this.attachmentDeleted = true;
          },
          error: (err) => {
            this.visibleUploadDialog = false;
          },
        });
    }
  }

  isButtonDisabled(): boolean {
    return (
      this.productionSiteForm.invalid ||
      (this.productionSiteForm.pristine &&
        this.selectedFilesAttachment.length === 0 &&
        !this.attachmentDeleted)
    );
  }

  protected readonly ExtensionsEnum = ExtensionsEnum;
  protected readonly environment = environment;
}
